<template>
  <transition name="modal">
    <div
      v-if="props.modelValue"
      id="modal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 flex w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0"
      style="background: rgba(0,0,0,0.8);"
    >
      <div
        class="absolute w-full top-20 left-1/2 right-1/2 -translate-x-1/2 z-70"
        :class="props.xl ? 'max-w-8xl' : props.lg ? 'max-w-xl' : 'max-w-md'"
      >
        <div class="relative bg-white rounded shadow dark:bg-gray-700 fade-modal">
          <button
            v-if="!props.exclusion"
            type="button"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            @click="() =>{
              emits('update:modelValue', false)
            }"
          >
            <CloseIcon />
          </button>
          <h3
            class="text-xl font-medium text-gray-900 dark:text-white rounded-t-lg px-6 py-4"
          >
            <slot name="title" />
          </h3>
          <div class="px-6 py-6 lg:px-8">
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
import CloseIcon from '~/components/icons/Close.vue'

const props = defineProps<{
  modelValue: boolean,
  exclusion?:boolean,
  xl?: boolean,
  lg?: boolean,
}>()

interface Emits {
  (e: 'update:modelValue', val: boolean): void;
}
const emits = defineEmits<Emits>()

onMounted(() => {
  document.addEventListener('keyup', (evt) => {
    if (props.modelValue) {
      if (evt.keyCode === 27) {
        emits('update:modelValue', false)
      }
    }
  })
})

</script>
<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: all 0.15s ease-in-out;

  .fade-modal {
    transform: translateY(0px);
    transition: all 0.15s ease-in-out;
  }
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;

  .fade-modal {
    transform: translateY(16px);
  }
}
</style>
